import { createContext, useContext } from 'react';
import type { ReactNode } from 'react';

const InSpaContext = createContext<boolean>(false);

export const useInSpa = (): boolean => {
  return useContext(InSpaContext);
};

export const InSpaProvider = ({
  inSpa,
  children,
}: {
  inSpa: boolean;
  children: ReactNode;
}): ReactNode => {
  return <InSpaContext.Provider value={inSpa}>{children}</InSpaContext.Provider>;
};
